<template>
  <div class="offertalks">
    <h1>Offer Talks</h1>
    <p class="descript">{{offerTalkConfig.content}}</p>
    <el-form :model="form" class="form" :label-position="'top'">
      <el-form-item label="请选择类别">
        <el-radio-group v-model="form.type" size="small">
          <el-radio-button
            :label="type"
            v-for="(type, tIndex) in offerTalkConfig.tags"
            :key="tIndex"
          ></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="请输入联系方式">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" class="w100" style="position: fixed;bottom: 50px" :disabled="offerTalkConfig.submitData != null">报名</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      class="notice"
      title="成功报名"
      :visible.sync="noticeVisible"
      width="20vw"
      top="25vh"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <div class="text">一般我们会在当天内联系你，请注意电话或微信信息</div>
      <div slot="footer" class="notice-footer" @click="onConfirm">
        <el-button type="text">知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOfferTalkIndex, postOfferTalk } from "@/apis/offertalk";

export default {
  data() {
    return {
      offerTalkConfig:null,
      isMember: false,
      noticeVisible: false,
      form: {
        type: null,
        phone: null,
      },
    };
  },
  created() {
    getOfferTalkIndex().then(res=>{
      this.offerTalkConfig = res.data
      if(res.data.submitData){
        this.form.type = res.data.submitData.tag
        this.form.phone = res.data.submitData.mobile
      }
    })
  },
  methods: {
    onSubmit() {
      postOfferTalk(this.form.type, this.form.phone).then(res => {
        this.noticeVisible = res.data;
      });
    },
    onConfirm() {
      this.noticeVisible = false;
      this.$router.push({
        path: "/",
        query: {
        }
      });
    }
  },
};
</script>

<style lang="scss">
.offertalks {
  margin: 54px auto 0;
  padding: 80px 147px;
  width: 974px;
  border: 1px solid rgba(166, 166, 166, 1);
  h1 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(20, 61, 206, 1);
  }
  .descript {
    margin-top: 8px;
    margin-bottom: 20px;
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    white-space: pre-wrap;
  }
  .form {
    width: 100%;
    .el-radio-button__inner {
      background: rgba(242, 242, 242, 1);
      color: rgba(166, 166, 166, 1);
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(237, 245, 255, 1);
      color: rgba(20, 61, 206, 1);
    }
    .el-form-item__label {
      display: block;
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      line-height: 32px;
      color: rgba(199, 199, 199, 1);
    }
    .w100 {
      margin: 10px auto;
      width: 680px;
      background-color: rgba(20, 61, 206, 1);
    }
  }
  .notice {
    .el-dialog {
      border-radius: 15px;
    }
    .el-dialog__body,
    .el-dialog__header,
    .el-dialog__footer {
      padding: 0;
    }
    .el-dialog__header {
      padding-top: 34px;
    }
    .notice-footer {
      cursor: pointer;
      border-top: 1px solid rgba(166, 166, 166, 1);
    }
    .text {
      padding: 20px 0;
      width: 70%;
      margin: 0 auto;
      opacity: 1;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      color: rgba(128, 128, 128, 1);
    }
    .el-button--text {
      color: rgba(20, 61, 206, 1);
    }
  }
}
</style>
