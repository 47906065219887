import { requestGet, requestPost } from "@/server/axios.js";
import { baseURL } from "@/server/config.js";

export const getOfferTalkIndex = () => {
  return requestGet(`${baseURL}/offer-talks/index`, {
  })
}

export const postOfferTalk = (tag, mobile) => {
  return requestPost(`${baseURL}/offer-talks/submit`, {
    tag,mobile
  })
}